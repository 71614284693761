import { Switch } from "react-router-dom";

import Library from "./components/Library";
import Event from "./components/Event";
import Navbar from "./components/Navbar";
import ProtectedRoute from "./auth/protectedRoute";
import { useAuth0 } from "@auth0/auth0-react";
import Subscription from "./components/Subscription";
import Success from "./components/Success";
import Home from "./components/Home";
import UpcomingForums from "./components/UpcomingForums";
import Error from "./components/Error";
import UserContext from "./context/UserContext";
import { useEffect, useState } from "react";
import axios from "axios";
import LoadingSkeleton from "./components/LoadingSkeleton";

import "./styles/output.css";
import Footer from "./components/Footer";

const App = () => {
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const [userInfo, setUserInfo] = useState({ token: "", type: "" });

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: "read:users_app_metadata read:client_grants read:users create:users_app_metadata update:users_app_metadata",
          },
        });
        const {
          data: { type },
        } = await axios.get("/api/userInfo", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserInfo({ token, type });
      } catch (err) {
        console.error(err);
      }
    };

    if (!isLoading) {
      getUserInfo();
    }
  }, [getAccessTokenSilently, isLoading]);

  return (
    <UserContext.Provider value={userInfo}>
      <div className="h-screen">
        <Navbar />
        {isLoading ? (
          <div className="mt-6">
            <LoadingSkeleton />
          </div>
        ) : (
          <>
            {" "}
            <Switch>
              <ProtectedRoute path="/success" component={Success} />
              <ProtectedRoute path="/subscription" component={Subscription} />
              <ProtectedRoute path="/library/:id" component={Event} />
              <ProtectedRoute path="/library" component={Library} />
              <ProtectedRoute
                path="/upcoming-forums"
                component={UpcomingForums}
              />
              <ProtectedRoute path="/error/:errorType" component={Error} />
              <ProtectedRoute path="/error" component={Error} />

              <ProtectedRoute path="/" component={Home} />
            </Switch>
          </>
        )}
        <Footer />
      </div>
    </UserContext.Provider>
  );
};

export default App;
